<template>
  <div v-if="$can('project-manager')">
    <h4 class="fw-bold mb-4 d-flex page-title">
          <span class="mr-auto">
            {{$trans('Projects')}}
          </span>
      <button @click="addForm = true" class="btn btn-primary btn-sm mr-3">
        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line><line data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line></svg>
        {{$trans('Add new')}}
      </button>
      <button @click="formFilter = !formFilter" class="btn btn-primary btn-sm">
        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
             stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 mr-1">
          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
        </svg>
        {{ $trans('Filter') }}
      </button>
    </h4>
    <div v-if="formFilter" class="card mb-3">
      <div class="card-body">
        <div class="d-flex">
          <div class="form-group mr-3">
            <label>{{ $trans('Keyword') }}</label>
            <input v-model="filterQuery.keyword" :placeholder="'keyword here!'" type="text" class="form-control">
          </div>
          <div class="form-group mr-3">
            <label>{{ $trans('Group') }}</label>
            <Select2 class="w-200" v-model="filterQuery.group" :options="project_groups"/>
          </div>
          <div class="form-group mr-3">
            <label>{{ $trans('User') }}</label>
            <Select2 class="w-200" v-model="filterQuery.user_id" :options="users"/>
          </div>
          <div class="form-group mr-3">
            <label>{{ $trans('Status') }}</label>
            <select class="form-control" v-model="filterQuery.status">
              <option value="all">{{$trans('All')}}</option>
              <option value="1">{{$trans('Active')}}</option>
              <option value="0">{{$trans('Inactive')}}</option>
            </select>
          </div>
        </div>
        <div class="filter-actions d-flex">
          <button @click="reset_filter" class="btn btn-outline-danger btn-sm mr-3">{{ $trans('Reset') }}</button>
          <button @click="index" class="btn btn-outline-primary btn-sm mr-3">
            <div v-if="process_loading" class="spinner-border spinner-border-sm text-secondary"></div>
            {{ $trans('Apply') }}
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-if="projects && projects.data" class="table-responsive">
          <div class="list-info mb-3">
            <small>{{$trans('Results')}}: <strong>{{projects.data.length}}</strong> {{$trans('of')}} <strong>{{projects.total}}</strong></small>
          </div>
          <table class="table table-striped mb-3">
            <thead>
            <tr>
              <th>{{$trans('Project name')}}</th>
              <th>{{$trans('Desc')}}</th>
              <th>{{$trans('Group')}}</th>
              <th>{{$trans('Project manager')}}</th>
              <th>{{$trans('Status')}}</th>
              <th>{{$trans('Date')}}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(project,index) in projects.data" v-bind:key="index">
              <td>{{project.name}}</td>
              <td class="pre-line">{{project.desc}}</td>
              <td>{{project.group}}</td>
              <td>
                <slot v-if="project.users">
                  <span class="badge badge-success badge-pill m-1" v-for="(user,k) in project.users" :key="k">{{user.name}}</span>
                </slot>
                <slot v-else>-</slot>
              </td>
              <td>
                <span v-if="project.status == 1" class="badge badge-primary badge-pill">{{$trans('Active')}}</span>
                <span v-if="project.status == 0" class="badge badge-warning badge-pill">{{$trans('Inactive')}}</span>
              </td>
              <td>{{project.created_at}}</td>
              <td class="text-right td-control">
                <a @click="edit(project)" class="mr-1" href="javascript:void(0)">
                  <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
                       stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                  </svg>
                </a>
                <a @click="destroy(project)" class="text-danger" href="javascript:void(0)">
                  <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
                       stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
          <paginate v-if="projects && projects.data && projects.last_page"
                    :page-count="projects.last_page"
                    :click-handler="paginate"
                    :prev-text="$trans('Previous')"
                    :next-text="$trans('Next')"
                    :page-class="'page-item'"
                    :prev-class="'page-item'"
                    :next-class="'page-item'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
    <modal v-if="addForm" v-bind:width="'500px'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{$trans('Add project')}}
          <button @click="addForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </h5>
        <small>{{$trans('Fields Marked with (*) are required')}}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{alert.message}}
        </div>
        <div class="form-group">
          <label>{{$trans('Name')}} (*)</label>
          <input v-model="addFields.name" class="form-control" type="text">
          <slot v-if="errors && errors['name']">
            <div class="text-danger" v-for="error in errors['name']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Desc')}} (*)</label>
          <textarea class="form-control" v-model="addFields.desc"></textarea>
          <slot v-if="errors && errors['desc']">
            <div class="text-danger" v-for="error in errors['desc']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Group')}} (*)</label>
          <Select2 v-model="addFields.group" :options="project_groups" :settings="{  multiple:false}"  />
          <slot v-if="errors && errors['group']">
            <div class="text-danger" v-for="error in errors['group']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label >{{$trans('Project manager')}} (*)</label>
          <Select2 v-model="addFields.user_ids" :options="users" :settings="{  multiple:true}"  />
          <slot v-if="errors && errors['user_ids']">
            <div class="text-danger" v-for="error in errors['user_ids']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input id="login-locked" class="form-check-input" type="checkbox" v-model="addFields.status" true-value="1" false-value="0">
            <label class="form-check-label" for="login-locked">
              <span>{{$trans('Active')}}</span>
            </label>
          </div>
        </div>
        <button @click="store" class="btn btn-primary">
          <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
          {{$trans('Add')}}
        </button>
      </slot>
    </modal>
    <modal v-if="editForm" v-bind:width="'500px'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{$trans('Edit project')}}
          <button @click="editForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </h5>
        <small>{{$trans('Fields Marked with (*) are required')}}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{alert.message}}
        </div>
        <div class="form-group">
          <label>{{$trans('Name')}} (*)</label>
          <input v-model="updateFields.name" class="form-control" type="text">
          <slot v-if="errors && errors['name']">
            <div class="text-danger" v-for="error in errors['name']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Desc')}} (*)</label>
          <textarea class="form-control" v-model="updateFields.desc"></textarea>
          <slot v-if="errors && errors['desc']">
            <div class="text-danger" v-for="error in errors['desc']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Group')}} (*)</label>
          <Select2 v-model="updateFields.group" :options="project_groups" :settings="{  multiple:false}"  />
          <slot v-if="errors && errors['group']">
            <div class="text-danger" v-for="error in errors['group']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label >{{$trans('Project manager')}} (*)</label>
          <Select2 v-model="updateFields.user_ids" :options="users" :settings="{  multiple:true}"  />
          <slot v-if="errors && errors['user_ids']">
            <div class="text-danger" v-for="error in errors['user_ids']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input id="edit-status" class="form-check-input" type="checkbox" v-model="updateFields.status" true-value="1" false-value="0">
            <label class="form-check-label" for="edit-status">
              <span>{{$trans('Active')}}</span>
            </label>
          </div>
        </div>
        <button @click="update" class="btn btn-primary">
          <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
          {{$trans('Update')}}
        </button>
      </slot>
    </modal>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Projects",
  data:function () {
    return {
      formFilter:false,
      process_loading:false,
      addForm:false,
      editForm:false,
      filterQuery:{
        page:1,
        status:'all',
        keyword:'',
        group:'',
        user_id:'',
      },
      projects:null,
      alert:null,
      process:false,
      addFields:{
        status:1,
        user_ids:[]
      },
      errors:{},
      response_users:null,
      updateFields:{}
    }
  },
  methods:{
    index:function () {
      this.process_loading = true
      axios({url: this.$root.$data.api_url + '/api/projects',params:this.filterQuery, method: 'GET'})
          .then(resp => {
            this.projects = resp.data;
            this.process_loading = false
          })
          .catch(err => {
            console.log(err)
          })
    },
    reset_filter:function () {
      this.filterQuery ={
        page:1,
        status:'all',
        keyword:'',
        group:'',
        user_id:'',
      }
      this.index()
    },
    filter:function (){
      this.filterQuery.page = 1;
      this.index();
    },
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    store:function () {
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/projects', data:this.addFields,method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.addForm = false;
                this.index();
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    get_users:function () {
      axios({url: this.$root.$data.api_url + '/api/all_user',params: {roles:[]}, method: 'GET'})
          .then(resp => {
            this.response_users = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    edit:function (project){
      axios({url: this.$root.$data.api_url + '/api/projects/'+project._id, method: 'GET'})
          .then(resp => {
            this.updateFields = resp.data.data;
            this.editForm = true;
          })
          .catch(err => {
            console.log(err)
          })
    },
    update:function (){
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/projects/'+this.updateFields._id, data:this.updateFields,method: 'PUT'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.editForm = false;
                this.index();
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    destroy:function (project){
      var actionConfirm = confirm("Are you sure?");
      if (actionConfirm === false) {
        return false;
      }
      axios({url: this.$root.$data.api_url + '/api/projects/' + project._id, method: 'DELETE'})
          .then(() => {
            this.index();
          })
          .catch(err => {
            console.log(err)
          })
    }
  },
  created() {
    this.index()
    this.get_users()
  },
  computed:{
    users:function () {
      if( !this.response_users){
        return [];
      }
      let users =[];
      let  response_users = this.response_users;
      response_users.forEach((el)=>{
        let item ={
          id:el._id,
          text:el.username
        }
        users.push(item)
      })

      return  users;
    },
    project_groups:function (){
        return this.$root.$data.global_settings.project_groups
    }
  }
}
</script>

<style scoped>

</style>